"use client";
import { SearchContext } from "@/contexts/search-context";
import { useOutsideClick } from "@/hooks/use-outside-click";
import { lockBodyWhenModalIsOpen } from "@/utils/modal-utils";
import algoliasearch from "algoliasearch/lite";
import { useContext, useEffect, useState } from "react";
import { Dialog } from "../base/dialog";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { VerticalContainer } from "../layout/vertical-container";
import { SearchBox } from "./search-box";
import { SearchResults } from "./search-results";

import { sendEvent } from "@/analytics/event-logger";
import { UserContext } from "@/contexts/user-context";
import type { MultipleQueriesQuery } from "@algolia/client-search";
import { take } from "rambda";
import type { KeyboardEvent } from "react";
import { Configure, InstantSearch, useHits, useSearchBox } from "react-instantsearch";
import type { Hit } from "./search-hit";

function SearchInner() {
  const { searchModalActive, setSearchModalActive } = useContext(SearchContext);

  const refDialog = useOutsideClick(() => {
    if (searchModalActive) {
      lockBodyWhenModalIsOpen(false);
      setSearchModalActive(false);
    }
  });
  const { query } = useSearchBox();
  const { hits } = useHits();
  const typedHits = hits as Array<Hit>;
  const cappedHits = take(5, typedHits);
  //focused element with notation "<index>,<id>"
  const [focusedElement, setFocusedElement] = useState<string | null>(null);

  useEffect(() => {
    if (hits.length > 0) {
      sendEvent({ event: "Hits Viewed" });
    }
  }, [hits.length]);

  function handleKeyDown(event: KeyboardEvent<HTMLDivElement>) {
    if (event.key === "Escape") {
      event.preventDefault();
      lockBodyWhenModalIsOpen(false);
      setSearchModalActive(false);
    }

    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      //if not search field selected then select
      if (!focusedElement) {
        document.getElementById("search-box")?.focus();
        setFocusedElement("search-box");
        return;
      }
      //split "<index>,<id>"
      const split = focusedElement.split(",");
      if (event.key === "ArrowDown") {
        //if moving from search field to first result
        if (focusedElement === "search-box" && cappedHits.length > 0) {
          document.getElementById(cappedHits[0].id)?.focus();
          setFocusedElement(`0,${cappedHits[0].id}`);
          //do not move past last element
        } else if (+split[0] + 1 < cappedHits.length) {
          const index = cappedHits.findIndex((hit) => hit.id === focusedElement.split(",")[1]);
          document.getElementById(cappedHits[index + 1].id)?.focus();
          setFocusedElement(`${index + 1},${cappedHits[index + 1].id}`);
        }
      }

      if (event.key === "ArrowUp") {
        //move from search result to search box
        if (focusedElement !== "search-box" && +split[0] === 0) {
          //inputfield has to be focused before setSelectionRange can be called further down
          const inputField = document.getElementById("search-box") as HTMLInputElement | null;
          //have to use this for setSelectionRange to work.
          setTimeout(() => {
            inputField?.focus();
          }, 0);
          //set cursor to end of query string
          inputField?.setSelectionRange(inputField?.value.length, inputField?.value.length);
          setFocusedElement("search-box");
          //do not move past first element
        } else if (+split[0] > 0) {
          const index = cappedHits.findIndex((hit) => hit.id === split[1]);
          document.getElementById(cappedHits[index - 1].id)?.focus();
          setFocusedElement(`${index - 1},${cappedHits[index - 1].id}`);
        }
      }
    }
  }

  //reset focused element to search box when query changes
  useEffect(() => {
    setFocusedElement("search-box");
  }, [query]);

  return (
    <div className="search-dialog" onKeyDown={(e) => handleKeyDown(e)}>
      <Dialog placement="top" isOpen={searchModalActive} id={"test"} backgroundColor="transparent" width="wider">
        <div ref={refDialog}>
          <VerticalContainer width="full" height="full">
            <SearchBox />
            <HorizontalSpacer size="small" />
            {query.length > 0 && <SearchResults hits={cappedHits} />}
          </VerticalContainer>
        </div>
      </Dialog>
    </div>
  );
}

export const Search = () => {
  const appId = process.env.NEXT_PUBLIC_ALGOLIA_PUBLIC_APP_ID;
  const apiKey = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY;
  const index = process.env.NEXT_PUBLIC_ALGOLIA_INDEX;

  const { user } = useContext(UserContext);

  if (!(appId && apiKey && index)) {
    throw new Error(
      "Search is not configured correctly, NEXT_PUBLIC_ALGOLIA_PUBLIC_APP_ID , NEXT_PUBLIC_ALGOLIA_API_KEY and NEXT_PUBLIC_ALGOLIA_INDEX must be configured",
    );
  }
  const client = algoliasearch(appId, apiKey);
  //avoid empty queries https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/js/
  const searchClient = {
    ...client,
    search(requests: readonly MultipleQueriesQuery[]) {
      if (requests.every(({ params }) => !params?.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            exhaustiveNbHits: false,
            hits: [],
            hitsPerPage: 0,
            nbHits: 0,
            nbPages: 0,
            page: 0,
            params: "",
            processingTimeMS: 0,
            query: "",
          })),
        });
      }
      return client.search(requests);
    },
  };

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={index}
      future={{ preserveSharedStateOnUnmount: true }}
      insights={true}
    >
      <Configure clickAnalytics={true} userToken={user?.id ?? undefined} />
      <SearchInner />
    </InstantSearch>
  );
};
