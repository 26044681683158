"use client";
import type { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import type { ReactNode } from "react";

interface AuthProviderProps {
  children: ReactNode;
  session: Session | null;
}

export function AuthContext({ children, session }: AuthProviderProps) {
  // Re-fetch session every 5 minutes
  return (
    <SessionProvider refetchOnWindowFocus={true} session={session} refetchInterval={15 * 60}>
      {children}
    </SessionProvider>
  );
}
