"use client";

import { Button } from "@/components/base/button";
import { PageError } from "@/components/error/page-error";
import { Logger } from "@/utils/logger";
import { useEffect } from "react";

export default function GlobalError({
  reset,
  error,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    Logger.error(error);
  }, [error]);

  return (
    <>
      <header />

      <main>
        <PageError errorCode="500" heading="Noe gikk galt" />

        <Button
          buttonType="secondary"
          onClick={
            // Attempt to recover by trying to re-render the segment
            () => reset()
          }
        >
          Last siden inn igjen
        </Button>
      </main>

      <footer />
    </>
  );
}
