import type { DirectDebitConsentFragment } from "@/generated/client.generated";

export const shouldShowDirectDebitNotification = (
  myDirectDebitConsents: DirectDebitConsentFragment[],
  hasActiveMembership: boolean,
  hasOtherPayer: boolean,
): boolean => {
  return (
    hasActiveMembership &&
    !hasOtherPayer &&
    myDirectDebitConsents.length > 0 &&
    myDirectDebitConsents.find((ddc) => ddc.status === "ACTIVE") === undefined
  );
};
