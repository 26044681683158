"use client";

import { classNames } from "@/utils/styling-utils";
import { default as LinkNext } from "next/link";
import type { ReactNode, SyntheticEvent } from "react";
import type React from "react";

interface LinkProps {
  ariaLabel?: string;
  children: ReactNode;
  href?: string;
  id?: string;
  newTab?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  textDecoration?: "none"; // 'underline' er default
  prefetch?: boolean;
}

export const Link = ({
  ariaLabel,
  children,
  href,
  id,
  newTab,
  onClick,
  onKeyDown,
  textDecoration,
  prefetch = true,
}: LinkProps) => (
  <LinkNext
    aria-label={ariaLabel}
    className={classNames("link", textDecoration && `link__text-decoration--${textDecoration}`)}
    href={href || ""}
    id={id}
    onClick={onClick && onClick}
    target={newTab ? "_blank" : "_self"}
    title={ariaLabel}
    prefetch={prefetch}
    onKeyDown={onKeyDown}
  >
    {children}
  </LinkNext>
);
