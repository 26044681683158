"use client";

import { useSession } from "next-auth/react";
import { useContext } from "react";

import { MenuContext } from "@/contexts/menu-context";
import { UserContext } from "@/contexts/user-context";
import { lockBodyWhenModalIsOpen } from "@/utils/modal-utils";
import { ROOT_URL } from "@/utils/paths-utils";
import { shouldShowDirectDebitNotification } from "@/utils/user-utils";
import { usePathname } from "next/navigation";
import { Link } from "../base/link";
import { UserIconOutline, UserIconSolid } from "../icons/icons";

export const MenuUserButton = () => {
  const { data: session } = useSession();
  const { setMobileMenuIsOpen } = useContext(MenuContext);
  const { hasOtherPayer, myDirectDebitConsents, hasActiveMembership } = useContext(UserContext);

  const path = usePathname();
  const currentUrl = ROOT_URL + path;

  const shouldShowDebitNotification = shouldShowDirectDebitNotification(
    [...myDirectDebitConsents],
    hasActiveMembership,
    hasOtherPayer,
  );

  if (session) {
    return (
      <Link
        href="/min-side"
        onClick={() => {
          lockBodyWhenModalIsOpen(false);
          setMobileMenuIsOpen(false);
        }}
      >
        <UserIconSolid name="Min side" alert={shouldShowDebitNotification ? "EXCLAMATION" : undefined} />
      </Link>
    );
  }

  return (
    <Link
      href={`/logg-inn?callbackUrl=${currentUrl}`}
      onClick={() => {
        lockBodyWhenModalIsOpen(false);
        setMobileMenuIsOpen(false);
      }}
    >
      <UserIconOutline name="Logg inn" />
    </Link>
  );
};
