"use client";

import { paths } from "@/utils/paths-utils";
import { Heading } from "../base/heading";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { VisuallyHidden } from "../layout/visually-hidden";
import { BigColoredText } from "../ui/big-colored-text";
import { LinkButton } from "../ui/link-button";
import { Text } from "../ui/text";

interface PageErrorProps {
  errorCode?: string;
  heading: string;
  linkText?: string;
  text?: string;
  url?: string;
}

export const PageError = ({ errorCode, heading, linkText, text, url }: PageErrorProps) => {
  return (
    <>
      <VisuallyHidden>
        <Heading type="h1">{heading}</Heading>
      </VisuallyHidden>

      <HorizontalSpacer size="medium" />

      {errorCode && (
        <>
          <BigColoredText>
            <h2>{errorCode}</h2>
          </BigColoredText>
          <HorizontalSpacer size="medium" />
        </>
      )}

      <Text value="Ops, her gikk det galt!" weight="bold" />

      {text && <Text value={text} weight="bold" />}

      <HorizontalSpacer size="large" />

      <LinkButton buttonType="secondary" href={url ?? paths.hjem}>
        {linkText ?? "Ta meg til forsiden"}
      </LinkButton>
      <HorizontalSpacer size="medium" />
    </>
  );
};
