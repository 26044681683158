"use client";

import { sendEvent } from "@/analytics/event-logger";
import {
  type DirectDebitConsentFragment,
  type FullUserFragment,
  type MyBookedEventFragment,
  MyBookingsDocument,
  type MyBookingsQuery,
  MyDirectDebitDocument,
  type MyDirectDebitQuery,
  type UserWorkoutSummaryFragment,
  useTrainingStatisticsLazyQuery,
  useUserLazyQuery,
} from "@/generated/client.generated";
import type { MyGroupActivityBookingTypes, ServicesBookingTypes } from "@/utils/booking-utils";
import { useLazyQuery } from "@apollo/client";
import { setUser as setSentryUser, setTag } from "@sentry/nextjs";
import { useSession } from "next-auth/react";
import { type ReactNode, createContext, useEffect, useState } from "react";

interface UserContextProps {
  user: FullUserFragment | undefined;
  token: string | undefined;
  myBookedActivities: ReadonlyArray<MyGroupActivityBookingTypes>;
  myBookedServices: ReadonlyArray<ServicesBookingTypes>;
  myBookedEvents: ReadonlyArray<MyBookedEventFragment>;
  myTrainingStatistics: UserWorkoutSummaryFragment | undefined;
  refetchMyBookedActivities: () => void;
  bookingsLoading: boolean;
  bookingsHasBeenInitialised: boolean;
  isEmployee: boolean;
  hasActiveMembership: boolean;
  myDirectDebitConsents: ReadonlyArray<DirectDebitConsentFragment>;
  hasOtherPayer: boolean;
}

export const UserContext = createContext<UserContextProps>({
  bookingsHasBeenInitialised: false,
  bookingsLoading: false,
  hasActiveMembership: false,
  hasOtherPayer: false,
  isEmployee: false,
  myBookedActivities: [],
  myBookedEvents: [],
  myBookedServices: [],
  myDirectDebitConsents: [],
  myTrainingStatistics: undefined,
  // biome-ignore lint/suspicious/noEmptyBlockStatements: <explanation>
  refetchMyBookedActivities: () => {},
  token: undefined,
  user: undefined,
});

interface UserProviderProps {
  children?: ReactNode;
}

export function UserProvider({ children }: UserProviderProps) {
  const session = useSession();

  const [user, setUser] = useState<FullUserFragment | undefined>();
  const [token, setToken] = useState<string | undefined>(undefined);

  const [refetchMyBookedActivities, setRefetchMyBookedActivities] = useState<boolean>(false);
  const [myBookings, setMyBookings] = useState<{
    activities: ReadonlyArray<MyGroupActivityBookingTypes>;
    services: ReadonlyArray<ServicesBookingTypes>;
    events: ReadonlyArray<MyBookedEventFragment>;
  }>({
    activities: [],
    services: [],
    events: [],
  });

  const [myTrainingStatistics, setMyTrainingStatistics] = useState<UserWorkoutSummaryFragment | undefined>();
  const [myDirectDebitConsents, setMyDirectDebitConsents] = useState<ReadonlyArray<DirectDebitConsentFragment>>([]);
  const [bookingsHasBeenInitialised, setBookingsHasBeenInitialised] = useState<boolean>(false);

  // const [userQuery] = useLazyQuery<UserQuery>(UserDocument);
  const [userQuery] = useUserLazyQuery();
  const [myBookingsQuery, { loading: bookingsLoading }] = useLazyQuery<MyBookingsQuery>(MyBookingsDocument);
  const [trainingStatisticsQuery] = useTrainingStatisticsLazyQuery();
  const [myDirectDebitConsentsQuery] = useLazyQuery<MyDirectDebitQuery>(MyDirectDebitDocument);

  const hasActiveMembership = (user?.subscriptions ?? []).length > 0;
  const isEmployee = session?.data?.user?.roles
    ? (session?.data?.user?.roles.some((role) => role === "ROLE_EMPLOYEE") ?? false)
    : false;

  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");

    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    if (user) {
      sessionStorage.setItem("user", JSON.stringify(user));
      setSentryUser({ id: user.id });
      setTag("subscription", user.subscriptions.length > 0 ? user.subscriptions[0].name : "no-subscription");
      setTag("isEmployee", isEmployee);
      sendEvent({
        has_active_membership: hasActiveMembership,
        is_employee: isEmployee,
        user_id: user.id,
      });
      sendEvent({ algoliaUserToken: token });
    } else {
      sessionStorage.removeItem("user");
    }
  }, [user, isEmployee, hasActiveMembership, token]);

  useEffect(() => {
    if (session.status !== "loading") {
      if (session.status === "authenticated") {
        userQuery().then((userResponse) => {
          if (userResponse?.data?.User) {
            setUser(userResponse.data.User);
          }
        });
        if (session?.data.user?.access_token) {
          setToken(session.data.user.access_token);
        }
      } else {
        setUser(undefined);
        setToken(undefined);
      }
    }
  }, [session, userQuery]);

  useEffect(() => {
    if (refetchMyBookedActivities || myBookings.activities.length === 0) {
      if (session.status === "authenticated") {
        myBookingsQuery({ fetchPolicy: "network-only" }).then((myActivitiesResponse) => {
          setMyBookings({
            activities: myActivitiesResponse.data?.CustomerGroupActivityBookings ?? [],
            services: myActivitiesResponse.data?.CustomerServiceBookings ?? [],
            events: myActivitiesResponse.data?.CustomerEventBookings ?? [],
          });
          setBookingsHasBeenInitialised(true);
        });
      } else {
        setMyBookings({ activities: [], services: [], events: [] });
      }
    }
    setRefetchMyBookedActivities(false);
  }, [session.status, refetchMyBookedActivities, myBookings.activities.length, myBookingsQuery]);

  useEffect(() => {
    if (session.status === "authenticated") {
      trainingStatisticsQuery({ fetchPolicy: "cache-first" }).then((trainingStatistics) => {
        setMyTrainingStatistics(trainingStatistics.data?.UserWorkoutSummary);
      });

      myDirectDebitConsentsQuery({ fetchPolicy: "cache-first" }).then((directDebitConsents) => {
        setMyDirectDebitConsents(directDebitConsents.data?.DirectDebitConsents ?? []);
      });
    } else {
      setMyTrainingStatistics(undefined);
      setMyDirectDebitConsents([]);
    }
  }, [session.status, trainingStatisticsQuery, myDirectDebitConsentsQuery]);

  return (
    <UserContext.Provider
      value={{
        bookingsHasBeenInitialised,
        bookingsLoading,
        hasActiveMembership,
        hasOtherPayer: user?.id !== user?.subscriptions[0]?.payer.id,
        isEmployee,
        myBookedActivities: myBookings.activities,
        myBookedEvents: myBookings.events,
        myBookedServices: myBookings.services,
        myDirectDebitConsents,
        myTrainingStatistics,
        refetchMyBookedActivities: () => setRefetchMyBookedActivities(true),
        token,
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
