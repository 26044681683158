import { HorizontalContainer } from "../layout/horizontal-container";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { VerticalContainer } from "../layout/vertical-container";
import { VerticalSpacer } from "../layout/vertical-spacer";
import { Text } from "../ui/text";
import { type Hit, SearchHit } from "./search-hit";

interface SearchResultsProps {
  hits: Array<Hit>;
}

export const SearchResults = ({ hits }: SearchResultsProps) => {
  const index = process.env.NEXT_PUBLIC_ALGOLIA_INDEX;

  return (
    <div className="search-results" data-insights-index={index}>
      {hits.map((hit: Hit) => (
        <SearchHit hit={hit} key={hit.id} />
      ))}
      {hits.length === 0 && (
        <VerticalContainer horizontalPlacement="center" verticalPlacement="center">
          <HorizontalSpacer size="x-large" />
          <HorizontalContainer>
            <VerticalSpacer size="x-large" />
            <Text size="small" style="italic" value="Søket ditt hadde dessverre ingen treff." />
            <VerticalSpacer size="x-large" />
          </HorizontalContainer>
          <HorizontalSpacer size="x-large" />
        </VerticalContainer>
      )}
    </div>
  );
};
