"use client";

import { classNames } from "@/utils/styling-utils";
import type { ReactNode } from "react";

interface DialogProps {
  children: ReactNode;
  id: string;
  isOpen: boolean;
  backgroundColor?: "white" | "transparent";
  placement?: "center" | "top";
  width: "normal" | "wider";
}

export const Dialog = ({
  children,
  id,
  isOpen,
  backgroundColor = "transparent",
  placement = "center",
  width = "normal",
}: DialogProps) =>
  isOpen && (
    <div className="dialog-modal-fade">
      <dialog
        aria-modal="true"
        className={classNames(
          "dialog",
          `dialog--placement--${placement}`,
          `dialog--background-color--${backgroundColor}`,
          `dialog--width--${width}`,
        )}
        id={id}
      >
        {children}
      </dialog>
    </div>
  );
