import { default as LinkNext } from "next/link";
import type { ReactNode, SyntheticEvent } from "react";

import { classNames } from "@/utils/styling-utils";
import type { ButtonSizeType, ButtonType } from "../base/button";
import { getButtonTypeSuffix } from "../base/button-utils";
import { ButtonVisibleArea } from "../base/button-visible-area";
import type { WidthType } from "../layout/layout.model";

/**
 * Use LinkButton if you want a link with routing (href) to look like a button
 */
interface LinkButtonProps {
  ariaLabel?: string;
  buttonType?: ButtonType;
  children: ReactNode;
  disabled?: boolean;
  href: string;
  onClick?: (e: SyntheticEvent) => void;
  openUrlInNewTab?: boolean;
  size?: ButtonSizeType;
  width?: WidthType;
  wrap?: boolean;
  scroll?: boolean;
  replace?: boolean;
}

export const LinkButton = ({
  ariaLabel,
  buttonType,
  children,
  disabled,
  href,
  onClick,
  openUrlInNewTab = false,
  size,
  width,
  wrap,
  scroll,
  replace,
}: LinkButtonProps) => {
  const buttonTypeSuffix = getButtonTypeSuffix(buttonType);

  return (
    <LinkNext
      aria-label={ariaLabel}
      className={classNames(
        "link-button button",
        buttonTypeSuffix && `button${buttonTypeSuffix}`,
        disabled && "button--disabled",
        size && `button--size--${size}`,
        width && `button--width--${width}`,
      )}
      href={disabled ? "" : href}
      onClick={onClick && onClick}
      target={openUrlInNewTab ? "_blank" : undefined}
      scroll={scroll}
      replace={replace}
    >
      <ButtonVisibleArea buttonTypeSuffix={buttonTypeSuffix} size={size} width={width} wrap={wrap}>
        {children}
      </ButtonVisibleArea>
    </LinkNext>
  );
};
