export const lockBodyWhenModalIsOpen = (modalIsOpen: boolean) => {
  // keep track of scroll position
  window.addEventListener("scroll", () => {
    document.documentElement.style.setProperty("--scroll-y", `${window.scrollY}px`);
  });

  // prevent scrolling of body when modal is open
  if (modalIsOpen) {
    const scrollY = document.documentElement.style.getPropertyValue("--scroll-y");
    const body = document.body;
    body.style.position = "fixed";
    body.style.top = `-${scrollY}`;
  }
  // reset when modal is closed
  else {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = "";
    body.style.top = "";
    window.scrollTo(0, Number.parseInt(scrollY || "0") * -1);
  }
};
