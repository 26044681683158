"use client";
import result from "@/generated/client.generated";
import { ApolloLink, createHttpLink } from "@apollo/client";
import {
  ApolloClient,
  ApolloNextAppProvider,
  InMemoryCache,
  SSRMultipartLink,
} from "@apollo/experimental-nextjs-app-support";
import type { Session } from "next-auth";
import { signOut, useSession } from "next-auth/react";
import { type ReactNode, useEffect, useState } from "react";
import { retryLink } from "./apollo-utils";

const backendHttpLink = () => {
  const url = process.env.NEXT_PUBLIC_BACKEND_URL || undefined;
  if (!url) {
    throw new Error("NEXT_PUBLIC_BACKEND_URL env variable not defined");
  }
  return createHttpLink({ uri: url });
};

const makeClient = (session: Session | null) => () => {
  const authMiddleware = new ApolloLink((operation, forward) => {
    // Add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        authorization: session ? `Bearer ${session.user?.access_token}` : null,
        ...headers,
      },
    }));

    return forward(operation);
  });

  return new ApolloClient({
    cache: new InMemoryCache({ possibleTypes: result.possibleTypes }),
    devtools: {
      enabled: process.env.NODE_ENV !== "production",
    },
    link: ApolloLink.from([
      // in a SSR environment, if you use multipart features like
      // @defer, you need to decide how to handle these.
      // This strips all interfaces with a `@defer` directive from your queries.
      new SSRMultipartLink({ stripDefer: true }),
      retryLink,
      authMiddleware,
      backendHttpLink(),
    ]),
  });
};
interface ApolloWrapperProps {
  session: Session | null;
  children: ReactNode;
}
export function ApolloWrapper({ session, children }: ApolloWrapperProps) {
  //Unsure if this is needed but ensure session always gets updated
  const [sessionState, setSessionState] = useState<Session | null>(session);
  const { data: clientSession } = useSession();

  useEffect(() => {
    if (clientSession) {
      if (clientSession?.error) {
        setSessionState(null);
      }
      setSessionState(clientSession);
    }

    if (clientSession?.error === "RefreshTokenError" || session?.error === "RefreshTokenError") {
      signOut({ callbackUrl: "/logg-inn" });
    }
  }, [clientSession, session]);

  useEffect(() => {
    setSessionState(session);
  }, [session, clientSession?.expires]);

  return <ApolloNextAppProvider makeClient={makeClient(sessionState)}>{children}</ApolloNextAppProvider>;
}
