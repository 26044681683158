import { capitalizeWord } from "@/utils/string-utils";
import { classNames } from "@/utils/styling-utils";
import type { FormatBaseProps } from "./ui.model";

interface TextProps extends FormatBaseProps {
  capitalize?: boolean;
  gradient?: boolean;
  noWrap?: boolean;
  shadow?: boolean;
  truncate?: boolean;
  underline?: boolean;
  value: string;
}

const cssClasses = (property: string, value?: string) => {
  if (!value) {
    return;
  }

  const className = `text__${property}--${value}`;
  return className;
};

export const Text = ({
  align,
  capitalize,
  color,
  gradient,
  noWrap,
  shadow,
  size,
  style,
  truncate,
  underline,
  uppercase,
  value,
  weight,
}: TextProps) => {
  const cssAlignClass = cssClasses("align", align);
  const cssColorClass = cssClasses("color", color);
  const cssSizeClass = cssClasses("size", size);
  const cssStyleClass = cssClasses("style", style);
  const cssWeightClass = cssClasses("weight", weight);

  return (
    <div
      className={classNames(
        "text",
        cssAlignClass,
        cssColorClass,
        cssSizeClass,
        cssStyleClass,
        cssWeightClass,
        noWrap && "text--no-wrap",
        shadow && "text--shadow",
        truncate && "text--truncate",
        underline && "text--underline",
        uppercase && "text--uppercase",
        gradient && "text--gradient",
      )}
    >
      {capitalize ? capitalizeWord(value) : value}
    </div>
  );
};
