import { classNames } from "@/utils/styling-utils";

interface HorizontalSpacerProps {
  size?:
    | "xx-small"
    | "x-small"
    | "small"
    | "medium"
    | "large"
    | "x-large"
    | "xx-large"
    | "line-height"
    | "line-height-small";
}

const cssClasses = (property: string, value?: string) => {
  if (!value) {
    return;
  }

  const className = `horizontal-spacer__${property}--${value}`;
  return className;
};

export const HorizontalSpacer = ({ size }: HorizontalSpacerProps) => {
  const cssSizeClass = cssClasses("size", size);

  return <div className={classNames("horizontal-spacer", cssSizeClass)} />;
};
