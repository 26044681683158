"use client";
import { type Dispatch, type SetStateAction, createContext } from "react";

interface MenuContextProps {
  mobileMenuIsOpen: boolean;
  setMobileMenuIsOpen: Dispatch<SetStateAction<boolean>>;
  currentOpenMenuSection: string | undefined;
  setCurrentOpenMenuSection: Dispatch<SetStateAction<string | undefined>>;
}

export const MenuContext = createContext<MenuContextProps>({
  currentOpenMenuSection: undefined,
  mobileMenuIsOpen: false,
  setCurrentOpenMenuSection: () => Promise.resolve,
  setMobileMenuIsOpen: () => Promise.resolve,
});
