import { SearchContext } from "@/contexts/search-context";
import { lockBodyWhenModalIsOpen } from "@/utils/modal-utils";
import { useContext, useRef, useState } from "react";
import { useSearchBox } from "react-instantsearch";
import { SearchIconGray, XIconGray } from "../icons/icons";

export const SearchBox = () => {
  const { query, refine, clear } = useSearchBox();
  const [inputValue, setInputValue] = useState(query);
  const inputRef = useRef<HTMLInputElement>(null);
  const { setSearchModalActive } = useContext(SearchContext);

  function setQuery(newQuery: string) {
    setInputValue(newQuery);

    refine(newQuery);
  }

  return (
    <div className="search-box">
      <div aria-label="Søke-ikon" className="search-box--input-adornment search-box--input-adornment--left">
        <SearchIconGray size="small" />
      </div>
      <input
        id="search-box"
        className="input"
        ref={inputRef}
        placeholder="Søk på 3T.no"
        autoFocus={true}
        value={inputValue}
        onChange={(event) => {
          setQuery(event.currentTarget.value);
        }}
      />

      {query.length > 0 && (
        <button
          aria-label="Tøm søkefelt"
          className="search-box--input-adornment search-box--input-adornment--right"
          onClick={() => {
            clear();
            setInputValue("");
            inputRef.current?.focus();
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              clear();
              setInputValue("");
              inputRef.current?.focus();
            }
          }}
          type="button"
          tabIndex={0}
        >
          <XIconGray size="small" />
        </button>
      )}
      <button
        type="button"
        aria-label="Lukk søkefelt"
        className="search-box--input-adornment search-box--input-adornment--right-right"
        onClick={() => {
          lockBodyWhenModalIsOpen(false);
          setSearchModalActive(false);
        }}
      >
        Lukk
      </button>
    </div>
  );
};
