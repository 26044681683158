import { captureException, captureMessage } from "@sentry/nextjs";

export const Logger = {
  error(error: unknown): void {
    captureException(error);
  },
  info(message: string): void {
    captureMessage(message);
  },
};
