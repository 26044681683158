"use client";

import { ResponsiveBreakpoints } from "@/utils/styling-utils";
import { useLayoutEffect, useState } from "react";

// we try and avoid using this hook as much as possible and rather use media-context
export function useIsDesktop(): boolean {
  const [isDesktop, setIsDesktop] = useState(false);

  useLayoutEffect(() => {
    function updateSize() {
      setIsDesktop(window.innerWidth >= ResponsiveBreakpoints.LARGE);
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return isDesktop;
}
