import { RetryLink } from "@apollo/client/link/retry";

export const retryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error) => !!error,
  },

  delay: {
    initial: 300,
    jitter: true,
    max: Number.POSITIVE_INFINITY,
  },
});
