import { classNames } from "@/utils/styling-utils";

interface AlertBubbleProps {
  alert?: number | "EXCLAMATION";
  raised?: boolean;
  size?: "x-small" | "small" | "large" | "x-large" | "xx-large";
}

const cssClasses = (property: string, value?: string) => {
  if (!value) {
    return;
  }

  return `alert-bubble__${property}--${value}`;
};

export const AlertBubble = ({ alert, raised, size }: AlertBubbleProps) => {
  const cssSizeClass = cssClasses("size", size);

  return (
    <div aria-hidden="true" className={classNames("alert-bubble", raised && "alert-bubble--raised", cssSizeClass)}>
      {alert === "EXCLAMATION" ? "!" : (alert ?? "!")}
    </div>
  );
};
