"use client";
import { Link } from "@/components/base/link";
import { MenuContext } from "@/contexts/menu-context";
import { lockBodyWhenModalIsOpen } from "@/utils/modal-utils";
import { classNames } from "@/utils/styling-utils";
import { usePathname } from "next/navigation";
import { useContext, useEffect, useState } from "react";

interface MenuItemProps {
  text: string;
  url: string;
  parentKey?: string;
}

export const MenuItem = ({ text, url, parentKey }: MenuItemProps) => {
  const { currentOpenMenuSection, setCurrentOpenMenuSection, setMobileMenuIsOpen } = useContext(MenuContext);
  const pathname = usePathname();
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (!url) {
      return;
    }

    if (!parentKey || currentOpenMenuSection !== parentKey) {
      setIsSelected(pathname === url && !currentOpenMenuSection);
    } else {
      setIsSelected(pathname === url);
    }
  }, [url, pathname, currentOpenMenuSection, parentKey]);

  return (
    <div className={classNames("menu-item", isSelected && "menu-item--selected")}>
      <Link
        href={url}
        onClick={() => {
          setCurrentOpenMenuSection(undefined);
          setMobileMenuIsOpen(false);
          lockBodyWhenModalIsOpen(false);
        }}
      >
        {text}
      </Link>
    </div>
  );
};
