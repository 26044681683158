import type { MainMenuBlockUnionFragment } from "@/generated/client.generated";
import { Text } from "../ui/text";
import { MenuItem } from "./menu-item";
import { MenuSection } from "./menu-section";

interface MenuBlockProps {
  block: MainMenuBlockUnionFragment;
}

export function MenuBlock({ block }: MenuBlockProps) {
  switch (block.__typename) {
    case "MenuSection": {
      if (block.links.length > 0) {
        return (
          <MenuSection title={block.title} url={block.links[0].url} key={block.title}>
            {block.links.map((link) => {
              return <MenuItem key={link.title} text={link.title} url={link.url} parentKey={block.title} />;
            })}
          </MenuSection>
        );
      }
      return <Text value={block.title} />;
    }

    case "MenuLink": {
      return <MenuItem key={block.title} text={block.title} url={block.url} />;
    }

    default: {
      return <Text value={"Not implemented yet"} />;
    }
  }
}
